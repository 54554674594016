.stack {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media screen and (max-width: 830px) {
    height: 700px;
  }

  .infos {
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h3 {
      text-transform: uppercase;
      font-style: italic;
    }
    p {
      font-size: 1rem;
      word-spacing: 0.4rem;
    }
  }
  .technos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "sass react ts"
      "sass react ts"
      "back back back";
    gap: 2%;
    @media screen and (max-width: 830px) {
      display: flex;
      flex-direction: column;
    }
    & > * {
      border: 1px solid grey;
      border-radius: 5px;
      padding: 0 5% 0 5%;
      @media screen and (max-width: 830px) {
        height: 20%;
        padding: 0 2% 0 2%;
      }
      .content {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          color: darkgray;
          font-size: 0.85rem;
        }
      }
    }

    .react {
      grid-area: react;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .img-container {
        height: 40%;
        width: auto;
        text-align: center;
        @media screen and (max-width: 830px) {
          width: 20%;
          height: 80%;
        }
        img {
          height: 100%;
          width: auto;
          animation: rotate 20s linear infinite;
        }
      }
      .content {
        @media screen and (max-width: 830px) {
          width: 70%;
        }
      }

      @media screen and (max-width: 830px) {
        flex-direction: row;
        align-items: center;
      }
    }
    .sass {
      grid-area: sass;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .img-container {
        height: 40%;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 830px) {
          width: 20%;
          height: 80%;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          height: 60%;
          width: auto;
        }
      }
      .content {
        @media screen and (max-width: 830px) {
          width: 70%;
        }
      }

      @media screen and (max-width: 830px) {
        flex-direction: row;
        align-items: center;
      }
    }
    .typescript {
      grid-area: ts;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .img-container {
        height: 40%;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 830px) {
          width: 20%;
          height: 80%;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          height: 60%;
          width: auto;
        }
      }
      .content {
        @media screen and (max-width: 830px) {
          width: 70%;
        }
      }

      @media screen and (max-width: 830px) {
        flex-direction: row;
        align-items: center;
      }
    }
    .backend {
      background: rgb(245, 245, 247);
      grid-area: back;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .img-container {
        margin: 0 auto;
        width: 80%;
        height: auto;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 830px) {
          width: 80%;
        }
        img {
          height: auto;
          &:first-of-type {
            width: 15%;
            @media screen and (max-width: 830px) {
              width: 25%;
            }
          }
          &:nth-of-type(2) {
            width: 30%;
            @media screen and (max-width: 830px) {
              width: 50%;
            }
          }
        }
      }
      .content {
        p {
          font-size: 1rem;
          color: grey;
          @media screen and (max-width: 830px) {
            font-size: 0.85rem;
          }
        }
      }

      @media screen and (max-width: 830px) {
        height: 30%;
        padding: 2% 5% 2% 5%;
      }
    }
  }
}
