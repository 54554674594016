.presentation {
  // height: 20vh;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .img-container {
    height: 100px; /* Définit une hauteur fixe */
    width: 100px; /* Définit une largeur fixe égale à la hauteur */
    border-radius: 50%; /* Applique un arrondi pour créer un cercle */
    overflow: hidden; /* Coupe les parties de l'image qui dépassent les bords arrondis */
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      width: 100%; /* Assure que l'image garde son ratio d'origine */
      object-fit: cover;
    }
  }

  .job {
    height: 40%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h2 {
      font-style: italic;
      font-weight: 100;
      text-transform: uppercase;
      @media screen and (max-width: 830px) {
        text-align: center;
      }
    }
    p {
      width: 70%;
      font-size: 0.9rem;
      word-spacing: 0.4rem;
      @media screen and (max-width: 830px) {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 830px) {
    // height: 30vh;
    height: 300px;
    flex-direction: column;
  }
}
