header {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    width: 50%;
    .logo {
      h1 {
        font-size: 1.6rem;
      }
    }
  }

  nav {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ul {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media screen and (max-width: 830px) {
        width: 30%;
        justify-content: space-between;
      }
      @media screen and (max-width: 750px) {
        width: 40%;
      }
      @media screen and (max-width: 550px) {
        width: 50%;
      }
    }
  }
}
