.project-container {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h3 {
    text-transform: uppercase;
    font-style: italic;
  }
  .project {
    width: 60%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 0 auto;
    background: url("../assets/img/cryptowatch.png") center/cover;
    position: relative;

    @media screen and (max-width: 830px) {
      width: 80%;
      height: 250px;
    }
    @media screen and (max-width: 510px) {
      width: 100%;
      height: 250px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      z-index: 1;
    }

    h4 {
      font-size: 2rem;
      text-align: center;
      z-index: 10;
      padding: 0 15% 0 15%;

      @media screen and (max-width: 700px) {
        padding: 0 10% 0 10%;
      }
    }
    ul {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10%;
      li {
        z-index: 10;
        background: #d4e8f7;
        color: #030621;
        border-radius: 30px;
        padding: 1% 3% 1% 3%;
        width: 15%;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 830px) {
          width: 20%;
        }
      }
    }
    a {
      z-index: 10;
      width: 25%;
      button {
        cursor: pointer;
        width: 100%;
        background: none;
        color: #d4e8f7;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 10% 5% 10% 5%;
        font-size: 1rem;
        transition: transform 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
