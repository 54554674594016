@font-face {
  font-family: lato;
  src: url("../assets/fonts/Lato-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: lato;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #030621;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 830px) {
    padding: 5%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #d4e8f7;
}

h3 {
  display: flex;
  align-items: center;
  &::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    margin-left: 10px;
    background: #d4e8f7;
  }
}

p {
  color: #d4e8f7;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: darkgray;
  transition: color 0.3s ease;
  &:hover {
    color: #d4e8f7;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animLogo {
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translate(0);
  }
}
