.tools-container {
  height: 500px;
  @media screen and (max-width: 550px) {
    height: 800px;
    margin-top: 50px;
  }

  h3 {
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 50px;
  }

  .tools {
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Ajoute un espace entre les éléments */

    & > div {
      flex: 1; /* Permet à chaque section d'occuper une largeur égale */
      min-width: 200px; /* Assure que chaque bloc ait une largeur minimale */

      h4 {
        margin-bottom: 10px; /* Espace entre le titre et la liste */
        text-transform: uppercase;
      }

      ul {
        // margin: 0; /* Supprime toute marge par défaut */
        // padding-left: 20px; /* Ajuste l'indentation de la liste */

        li {
          list-style: inside;
          color: darkgray;
          margin-bottom: 5px;
        }
      }
    }
  }
}
