@charset "UTF-8";
@font-face {
  font-family: lato;
  src: url("../assets/fonts/Lato-Regular.ttf");
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: lato;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #030621;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 830px) {
  body {
    padding: 5%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #d4e8f7;
}

h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
h3::after {
  content: "";
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 1px;
  margin-left: 10px;
  background: #d4e8f7;
}

p {
  color: #d4e8f7;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: darkgray;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
a:hover {
  color: #d4e8f7;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes animLogo {
  50% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes animLogo {
  50% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
.main {
  max-width: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

header {
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
header a {
  width: 50%;
}
header a .logo h1 {
  font-size: 1.6rem;
}
header nav {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
header nav ul {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media screen and (max-width: 830px) {
  header nav ul {
    width: 30%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (max-width: 750px) {
  header nav ul {
    width: 40%;
  }
}
@media screen and (max-width: 550px) {
  header nav ul {
    width: 50%;
  }
}

footer {
  margin-top: 50px;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
footer .contact {
  height: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
footer .contact h3 {
  text-transform: uppercase;
  font-style: italic;
}
footer .social {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 830px) {
  footer .social {
    width: 35%;
  }
}
@media screen and (max-width: 750px) {
  footer .social {
    width: 40%;
  }
}
@media screen and (max-width: 670px) {
  footer .social {
    width: 45%;
  }
}
@media screen and (max-width: 510px) {
  footer .social {
    width: 55%;
  }
}
footer .social a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
footer .social a img {
  width: 50%;
  height: auto;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
footer .social a img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.top {
  height: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: animLogo 1.2s infinite linear;
          animation: animLogo 1.2s infinite linear;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.top img {
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.top img:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.presentation {
  height: 200px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.presentation .img-container {
  height: 100px; /* Définit une hauteur fixe */
  width: 100px; /* Définit une largeur fixe égale à la hauteur */
  border-radius: 50%; /* Applique un arrondi pour créer un cercle */
  overflow: hidden; /* Coupe les parties de l'image qui dépassent les bords arrondis */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.presentation .img-container img {
  height: 100%;
  width: 100%; /* Assure que l'image garde son ratio d'origine */
  -o-object-fit: cover;
     object-fit: cover;
}
.presentation .job {
  height: 40%;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.presentation .job h2 {
  font-style: italic;
  font-weight: 100;
  text-transform: uppercase;
}
@media screen and (max-width: 830px) {
  .presentation .job h2 {
    text-align: center;
  }
}
.presentation .job p {
  width: 70%;
  font-size: 0.9rem;
  word-spacing: 0.4rem;
}
@media screen and (max-width: 830px) {
  .presentation .job p {
    width: 100%;
  }
}
@media screen and (max-width: 830px) {
  .presentation {
    height: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.stack {
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media screen and (max-width: 830px) {
  .stack {
    height: 700px;
  }
}
.stack .infos {
  height: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.stack .infos h3 {
  text-transform: uppercase;
  font-style: italic;
}
.stack .infos p {
  font-size: 1rem;
  word-spacing: 0.4rem;
}
.stack .technos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "sass react ts" "sass react ts" "back back back";
  gap: 2%;
}
@media screen and (max-width: 830px) {
  .stack .technos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.stack .technos > * {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 0 5% 0 5%;
}
@media screen and (max-width: 830px) {
  .stack .technos > * {
    height: 20%;
    padding: 0 2% 0 2%;
  }
}
.stack .technos > * .content {
  height: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.stack .technos > * .content p {
  color: darkgray;
  font-size: 0.85rem;
}
.stack .technos .react {
  grid-area: react;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.stack .technos .react .img-container {
  height: 40%;
  width: auto;
  text-align: center;
}
@media screen and (max-width: 830px) {
  .stack .technos .react .img-container {
    width: 20%;
    height: 80%;
  }
}
.stack .technos .react .img-container img {
  height: 100%;
  width: auto;
  -webkit-animation: rotate 20s linear infinite;
          animation: rotate 20s linear infinite;
}
@media screen and (max-width: 830px) {
  .stack .technos .react .content {
    width: 70%;
  }
}
@media screen and (max-width: 830px) {
  .stack .technos .react {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.stack .technos .sass {
  grid-area: sass;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.stack .technos .sass .img-container {
  height: 40%;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 830px) {
  .stack .technos .sass .img-container {
    width: 20%;
    height: 80%;
  }
}
.stack .technos .sass .img-container img {
  max-width: 100%;
  max-height: 100%;
  height: 60%;
  width: auto;
}
@media screen and (max-width: 830px) {
  .stack .technos .sass .content {
    width: 70%;
  }
}
@media screen and (max-width: 830px) {
  .stack .technos .sass {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.stack .technos .typescript {
  grid-area: ts;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.stack .technos .typescript .img-container {
  height: 40%;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 830px) {
  .stack .technos .typescript .img-container {
    width: 20%;
    height: 80%;
  }
}
.stack .technos .typescript .img-container img {
  max-width: 100%;
  max-height: 100%;
  height: 60%;
  width: auto;
}
@media screen and (max-width: 830px) {
  .stack .technos .typescript .content {
    width: 70%;
  }
}
@media screen and (max-width: 830px) {
  .stack .technos .typescript {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.stack .technos .backend {
  background: rgb(245, 245, 247);
  grid-area: back;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.stack .technos .backend .img-container {
  margin: 0 auto;
  width: 80%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media screen and (max-width: 830px) {
  .stack .technos .backend .img-container {
    width: 80%;
  }
}
.stack .technos .backend .img-container img {
  height: auto;
}
.stack .technos .backend .img-container img:first-of-type {
  width: 15%;
}
@media screen and (max-width: 830px) {
  .stack .technos .backend .img-container img:first-of-type {
    width: 25%;
  }
}
.stack .technos .backend .img-container img:nth-of-type(2) {
  width: 30%;
}
@media screen and (max-width: 830px) {
  .stack .technos .backend .img-container img:nth-of-type(2) {
    width: 50%;
  }
}
.stack .technos .backend .content p {
  font-size: 1rem;
  color: grey;
}
@media screen and (max-width: 830px) {
  .stack .technos .backend .content p {
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 830px) {
  .stack .technos .backend {
    height: 30%;
    padding: 2% 5% 2% 5%;
  }
}

.education {
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media screen and (max-width: 830px) {
  .education {
    height: 700px;
    margin-top: 0;
  }
}
.education h3 {
  text-transform: uppercase;
  font-style: italic;
  height: 10%;
}
.education .deust {
  height: 28%;
  border: 1px solid grey;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 2% 10% 2% 10%;
}
@media screen and (max-width: 830px) {
  .education .deust {
    height: 30%;
    padding: 2% 2% 2% 2%;
  }
}
.education .licence {
  height: 28%;
  border: 1px solid grey;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 2% 10% 2% 10%;
}
@media screen and (max-width: 830px) {
  .education .licence {
    height: 34%;
    padding: 2% 2% 2% 2%;
  }
}
.education .toeic {
  height: 28%;
  background: url("../assets/img/uk.png") center/cover;
  border: 1px solid white;
  border-radius: 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 830px) {
  .education .toeic {
    height: 20%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.education .toeic h4 {
  font-size: 2rem;
  color: black;
  text-transform: uppercase;
  font-style: italic;
}
@media screen and (max-width: 830px) {
  .education .toeic h4 {
    width: 50%;
    text-align: center;
  }
}
.education .toeic .details {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 830px) {
  .education .toeic .details {
    width: 50%;
  }
}
.education .toeic .details p {
  color: black;
  font-size: 1.2rem;
}
@media screen and (max-width: 830px) {
  .education .toeic .details p {
    display: none;
  }
}
.education .toeic .details p.score {
  font-size: 1.6rem;
  color: black;
}
@media screen and (max-width: 830px) {
  .education .toeic .details p.score {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.education .toeic .details span {
  color: white;
  text-shadow: 1px 1px 0px rgb(0, 0, 0);
}
.education h4 {
  text-transform: uppercase;
}
.education h5 {
  font-style: italic;
}
.education .description {
  color: darkgray;
  font-size: 0.85rem;
}

footer {
  margin-top: 50px;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
footer .contact {
  height: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
footer .contact h3 {
  text-transform: uppercase;
  font-style: italic;
}
footer .social {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 830px) {
  footer .social {
    width: 35%;
  }
}
@media screen and (max-width: 750px) {
  footer .social {
    width: 40%;
  }
}
@media screen and (max-width: 670px) {
  footer .social {
    width: 45%;
  }
}
@media screen and (max-width: 510px) {
  footer .social {
    width: 55%;
  }
}
footer .social a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
footer .social a img {
  width: 50%;
  height: auto;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
footer .social a img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.faq-container {
  height: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media screen and (max-width: 830px) {
  .faq-container {
    height: 900px;
  }
}
@media screen and (max-width: 700px) {
  .faq-container {
    height: 1100px;
  }
}
@media screen and (max-width: 550px) {
  .faq-container {
    height: 1200px;
    margin-top: 50px;
  }
}
.faq-container h3 {
  text-transform: uppercase;
  font-style: italic;
}
.faq-container .faq {
  height: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media screen and (max-width: 550px) {
  .faq-container .faq {
    height: 100%;
  }
}
.faq-container .faq .question-container p.question {
  font-size: 1.3rem;
  margin-bottom: 10px;
}
.faq-container .faq .question-container p.answer {
  color: darkgray;
  font-size: 0.9rem;
  word-spacing: 0.1rem;
  line-height: 2;
}

.tools-container {
  height: 500px;
}
@media screen and (max-width: 550px) {
  .tools-container {
    height: 800px;
    margin-top: 50px;
  }
}
.tools-container h3 {
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 50px;
}
.tools-container .tools {
  width: 100%;
  height: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px; /* Ajoute un espace entre les éléments */
}
.tools-container .tools > div {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; /* Permet à chaque section d'occuper une largeur égale */
  min-width: 200px; /* Assure que chaque bloc ait une largeur minimale */
}
.tools-container .tools > div h4 {
  margin-bottom: 10px; /* Espace entre le titre et la liste */
  text-transform: uppercase;
}
.tools-container .tools > div ul li {
  list-style: inside;
  color: darkgray;
  margin-bottom: 5px;
}

.project-container {
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.project-container h3 {
  text-transform: uppercase;
  font-style: italic;
}
.project-container .project {
  width: 60%;
  height: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 0 auto;
  background: url("../assets/img/cryptowatch.png") center/cover;
  position: relative;
}
@media screen and (max-width: 830px) {
  .project-container .project {
    width: 80%;
    height: 250px;
  }
}
@media screen and (max-width: 510px) {
  .project-container .project {
    width: 100%;
    height: 250px;
  }
}
.project-container .project::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 1;
}
.project-container .project h4 {
  font-size: 2rem;
  text-align: center;
  z-index: 10;
  padding: 0 15% 0 15%;
}
@media screen and (max-width: 700px) {
  .project-container .project h4 {
    padding: 0 10% 0 10%;
  }
}
.project-container .project ul {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10%;
}
.project-container .project ul li {
  z-index: 10;
  background: #d4e8f7;
  color: #030621;
  border-radius: 30px;
  padding: 1% 3% 1% 3%;
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 830px) {
  .project-container .project ul li {
    width: 20%;
  }
}
.project-container .project a {
  z-index: 10;
  width: 25%;
}
.project-container .project a button {
  cursor: pointer;
  width: 100%;
  background: none;
  color: #d4e8f7;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10% 5% 10% 5%;
  font-size: 1rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.project-container .project a button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}/*# sourceMappingURL=index.css.map */