.faq-container {
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media screen and (max-width: 830px) {
    height: 900px;
  }
  @media screen and (max-width: 700px) {
    height: 1100px;
  }
  @media screen and (max-width: 550px) {
    height: 1200px;
    margin-top: 50px;
  }
  h3 {
    text-transform: uppercase;
    font-style: italic;
  }
  .faq {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (max-width: 550px) {
      height: 100%;
    }
    .question-container {
      p.question {
        font-size: 1.3rem;
        margin-bottom: 10px;
      }
      p.answer {
        color: darkgray;
        font-size: 0.9rem;
        word-spacing: 0.1rem;
        line-height: 2;
      }
    }
  }
}
