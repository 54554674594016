footer {
  margin-top: 50px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .contact {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      text-transform: uppercase;
      font-style: italic;
    }
  }

  .social {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 830px) {
      width: 35%;
    }
    @media screen and (max-width: 750px) {
      width: 40%;
    }
    @media screen and (max-width: 670px) {
      width: 45%;
    }
    @media screen and (max-width: 510px) {
      width: 55%;
    }
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
        height: auto;
        transition: transform 0.3s;
        // @media screen and (max-width: 830px) {
        //   width: 40%;
        // }
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
