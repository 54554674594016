.top {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: animLogo 1.2s infinite linear;
  user-select: none;

  img {
    cursor: pointer;
    transition: transform 0.4s;

    &:hover {
      transform: scale(1.3);
    }
  }
}
