.education {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // margin-top: 50px;

  @media screen and (max-width: 830px) {
    height: 700px;
    margin-top: 0;
  }
  h3 {
    text-transform: uppercase;
    font-style: italic;
    height: 10%;
  }
  .deust {
    height: 28%;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2% 10% 2% 10%;
    @media screen and (max-width: 830px) {
      height: 30%;
      padding: 2% 2% 2% 2%;
    }
  }
  .licence {
    height: 28%;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2% 10% 2% 10%;
    @media screen and (max-width: 830px) {
      height: 34%;
      padding: 2% 2% 2% 2%;
    }
  }
  .toeic {
    height: 28%;
    background: url("../assets/img/uk.png") center/cover;
    border: 1px solid white;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 830px) {
      height: 20%;
      flex-direction: row;
    }
    h4 {
      font-size: 2rem;
      color: black;
      text-transform: uppercase;
      font-style: italic;
      @media screen and (max-width: 830px) {
        width: 50%;
        text-align: center;
      }
    }

    .details {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media screen and (max-width: 830px) {
        width: 50%;
      }
      p {
        color: black;
        font-size: 1.2rem;
        @media screen and (max-width: 830px) {
          display: none;
        }
      }
      p.score {
        font-size: 1.6rem;
        color: black;
        @media screen and (max-width: 830px) {
          display: flex;
        }
      }
      span {
        color: white;
        text-shadow: 1px 1px 0px rgb(0, 0, 0);
      }
    }
  }
  h4 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
  }
  .description {
    color: darkgray;
    font-size: 0.85rem;
  }
}
